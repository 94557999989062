import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';

import UniversalImage from 'components/common/UniversalImage';
import NFButton from 'components/common/NFButton';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IPainBannerExtendTitle } from './model';

import './PainBannerExtendTitle.scss';

const PainBannerExtendTitle: FC<IPainBannerExtendTitle> = ({
  title,
  image,
  imageMobile,
  imageAlt,
  header,
  headerSmall,
  buttonLink,
  text,
  sectionTheme,
}) => {
  const { isTablet, isMobile } = useScreenRecognition({ isTablet: true });

  return (
    <section
      className={classnames('pain-banner-extend-title', {
        [`pain-banner-extend-title--${sectionTheme}`]: sectionTheme,
      })}
    >
      <div className="pain-banner-extend-title__content">
        <div className="pain-banner-extend-title__header">
          {header ? (
            <div className="pain-banner-extend-title__header--main">
              <DangerouslySetInnerHtml html={header} />
            </div>
          ) : null}
          {headerSmall ? (
            <div className="pain-banner-extend-title__header--small">{headerSmall}</div>
          ) : null}
        </div>
        <h1 className="pain-banner-extend-title__title">{title}</h1>
        {buttonLink ? (
          <div className="pain-banner-extend-title__button">
            <NFButton {...buttonLink} />
          </div>
        ) : null}
        {text ? <p className="pain-banner-extend-title__text">{text}</p> : null}
      </div>
      {image ? (
        <div className="pain-banner-extend-title__image">
          <UniversalImage img={isTablet || isMobile ? imageMobile : image} imageAlt={imageAlt} />
        </div>
      ) : null}
    </section>
  );
};

export const query = graphql`
  fragment FragmentPainBannerExtendTitle on IPainBannerExtendTitle {
    title
    image {
      fallbackUrl
    }
    imageMobile {
      fallbackUrl
    }
    imageAlt
    header
    headerSmall
    buttonLink {
      properties {
        ...FragmentNFButton
      }
    }
    text
  }
`;

export default PainBannerExtendTitle;
